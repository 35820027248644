<template>
  <div id="app" class="MainAppOuter">
    <router-view />
    <div class="MainAppFoot">
      <p>This is a private website</p>
      <p>Copyright belongs to [Infinite Class 5]</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>
