export function str_data() {
    var astr = [
        {
            id: 20230501211931,
            date: "2023/05/01",
        },
        {
            id: 20230501173916,
            date: "2023/05/01",
        },
        {
            id: 20230414223114,
            date: "2023/04/14",
        },
    ];
    return astr;
}
export function str_update() {
    var date = {
        normal: "2023/05/01",
        last: "2023/05/01",
    };
    return date;
}
export function str_esign() {
    var esign = "LBS";
    return esign;
}
