<template>
    <div class="HomeView_Title">
        <h1>无限五班</h1>
        <h2>Infinite Class 5</h2>
    </div>
    <NavbarPlug></NavbarPlug>
    <Frame1List :eobj="eobj"></Frame1List>
</template>

<script>
import Frame1List from '@/components/Frame1List.vue';
import NavbarPlug from '@/components/NavbarPlug.vue';
export default {
    name: "HomeView",
    components: {
        NavbarPlug,
        Frame1List,
    },
    data() {
        return {
            eobj: require('@/data/HomeNotices.js'),
        }
    },
}
</script>
