import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import less from 'less'
import { marked } from 'marked'

import "@/beautify/light.less"


const app = createApp(App);
app.use(router);
app.use(less);
app.mixin(marked);
app.mount('#app');
