export function str_data() {
    var astr = [
        {
            id: 20240719000000,
            date: "2024/07/19",
        },
        {
            id: 20230501000000,
            date: "2023/05/01",
        },
        {
            id: 20230428000000,
            date: "2023/04/28",
        },
        {
            id: 20230420000000,
            date: "2023/04/20",
        },

    ];
    return astr;
}
export function str_update() {
    var date = {
        normal: "2023/05/01",
        last: "2024/07/19",
    };
    return date;
}
export function str_esign() {
    var esign = "HN";
    return esign;
}
