<template>
    <h1>=> [ERROR | 404]</h1>
    <h1>Emmm..</h1>
    <h1>You seem to GET a ERROR</h1>
</template>

<script>
export default {
    name: "BaseView404",
}
</script>
