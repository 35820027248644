<template>
    <div class="Frame1_Notice" v-for="row in narray" :key="row.id">
        <div v-html="require(`@/data/${esign}${row.id}a.md`)" class="div-markdown"></div>
        <p v-if="row.date" class="Frame1_Date">{{ row.date }}</p>
        <hr />
        <div v-html="require(`@/data/${esign}${row.id}b.md`)" class="div-markdown"></div>
    </div>
    <p class="Frame1_Date">Last Normal Update Date:{{ upDate.normal }}</p>
    <p class="Frame1_Date">Last Update Date:{{ upDate.last }}</p>
</template>

<script>
export default {
    name: "Frame1List",
    props: {
        eobj:{ type: Object, required:true, }
    },
    data() {
        return {
            narray: this.eobj.str_data(),
            upDate: this.eobj.str_update(),
            esign: this.eobj.str_esign(),
        };
    },
}
</script>
