<template>
    Here is nothing.
</template>

<script>
export default {
    name: "RootJmp",
    created() {
        location.replace("./home");
    },
}
</script>
