export function str_data() {
    var astr = [
        {
            id: 20230501000000,
            date: "2023/05/01",
        },
    ];
    return astr;
}
export function str_update() {
    var date = {
        normal: "2023/05/01",
        last: "2023/05/01",
    };
    return date;
}
export function str_esign() {
    var esign = "DG";
    return esign;
}
