// import Vue from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import BaseView404 from '@/view/BaseView404.vue';
import RootJmp from '@/view/RootJmp.vue';
import HomeView from '@/view/HomeView.vue';
import DoGerView from '@/view/DoGerView.vue';
import LaobanView from '@/view/LaobanView.vue';

const routes = [
    {
        path:'/',
        name: 'RootJmp',
        component: RootJmp,
        redirect: "/home",
    },
    {
        path:'/home',
        name: 'HomeView',
        component: HomeView,
    },
    {
        path:'/doger',
        name: 'DoGerView',
        component: DoGerView,
    },
    {
        path:'/laoban',
        name: 'LaobanView',
        component: LaobanView,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "BaseView404",
        component: BaseView404,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// router.beforeEach(async (to) =>{
//     if(to.params && to.params.name && valid_pokemon.indexOf(to.params.name) === -1){
//         return "/404";
//     }
// })

export default router;
