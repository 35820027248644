<template>
    <div class="NavbarPlug">
        <button onclick="window.location.href='./doger'">DoGer 箴言</button>
        <button onclick="window.location.href='./laoban'">老班 语录</button>
    </div>
</template>

<script>
export default {
    name: "NavbarPlug",
}
</script>