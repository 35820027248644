<template>
    <button id="back_btn" onclick="window.location.href='../home'">&lt;-返回</button>
    <h2>DoGer箴言</h2>
    <Frame1List :eobj="eobj"></Frame1List>
</template>

<script>
import Frame1List from '@/components/Frame1List.vue';

export default {
    name: "DoGerView",
    components:{
        Frame1List,
    },
    data(){
        return{
            eobj: require("@/data/DoGerSaying.js"),
        }
    }
}
</script>
